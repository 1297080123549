
import { Options, Vue } from "vue-class-component";
import { IProblem } from "../PracticeMath";

@Options({
  props: {
    problem: null,
    userName: String,
  },
})
export default class ProblemDisplay extends Vue {
  problem!: IProblem;
  userName!: string;
  focusNbr = 0;

  mounted(): void {
    this.problem.set_focus_number(this.focusNbr)
    this.problem.update_numbers();
  }

  focus_number_changed(): void {
    console.log(`Setting focus number - ${ this.focusNbr }`);
    this.problem.set_focus_number(this.focusNbr);
    this.problem.update_numbers();
    this.set_focus_on_answer_field();
  }

  set_focus_on_answer_field(): void {
    (this.$refs[`answer`] as any).focus();
  }

  check_answer(): void {
    const result = this.problem.check_answer();
    console.log(result);
    this.$emit("answerResult", result);
  }
}
