
import { Vue, Options } from "vue-class-component";
import ProblemDisplay from "./ProblemDisplay.vue";

import {
  IProblem,
  AnswerResult,
  AdditionProblem,
  SubtractionProblem,
  MultiplicationProblem,
  DivisionProblem,
} from "../PracticeMath";

@Options({
  components: {
    ProblemDisplay,
  },
})
export default class WorkArea extends Vue {
  additionProblem = new AdditionProblem();
  subtractionProblem = new SubtractionProblem();
  multiplicationProblem = new MultiplicationProblem();
  divisionProblem = new DivisionProblem();
  currentProblem: IProblem = new AdditionProblem();
  results: AnswerResult[] = [];
  currentPicked = "addition";
  loggedIn = false;
  userName = "";
  focusNbr = 0;

  get picked(): string {
    return this.currentPicked;
  }
  set picked(value: string) {
    this.focusNbr = this.currentProblem.focus_number;
    switch (value) {
      case "addition": {
        this.currentProblem = this.additionProblem;
        break;
      }
      case "subtraction": {
        this.currentProblem = this.subtractionProblem;
        break;
      }
      case "multiplication": {
        this.currentProblem = this.multiplicationProblem;
        break;
      }
      case "division": {
        this.currentProblem = this.divisionProblem;
        break;
      }
    }
    this.currentProblem.set_focus_number(this.focusNbr);
    this.currentProblem.update_numbers();
    (this.$refs['problemDisplay'] as any).set_focus_on_answer_field();
    this.currentPicked = value;
  }
  number_correct(): number {;
    return this.results.filter(correct).length;
  }
  total_tries(): number {
    return this.results.length;
  }
  mounted(): void {
    this.currentProblem.focus_number = this.focusNbr;
    this.check_authentication();
  }
  on_answer_result(value: AnswerResult): void {
    value.user_name = this.userName;
    this.results.push(value);
  }
  on_send_results(): void {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.results),
    };
    const logicAppUrl =
      "https://prod-07.northcentralus.logic.azure.com:443/workflows/72d860d9dd4f4a20acc05c9acc5513bd/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mf5bwW8XBKvVxzveJ6VC2MjyfPNO87UMMVnAhN_qXjY";
    fetch(logicAppUrl, requestOptions);
    this.results = [];
  }
  on_login(): void {
    window.location.href = "/.auth/login/aad";
  }
  on_logout(): void {
    window.location.href = "/.auth/logout";
  }
  check_authentication(): void {
    if (this.loggedIn == false) {
      fetch("/.auth/me")
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.clientPrincipal) {
            this.userName = data.clientPrincipal.userDetails;
            this.loggedIn = true;
          }
        });
    }
  }
}
function correct(answer: AnswerResult): boolean {
  return answer.correct;
}
