
import { Options, Vue } from "vue-class-component";
import WorkArea from "./components/PracticeMath.vue";

@Options({
  components: {
    WorkArea,
  },
})
export default class App extends Vue {}
