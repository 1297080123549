export interface IProblem {
  title: string;
  first: number;
  second: number;
  correct: boolean;
  answer: string;
  operation_symbol: string;
  message: string;
  start_time: number;
  end_time: number;
  focus_number: number;

  set_focus_number(focus_number: number): void;
  update_numbers(): void;
  compute_answer(): number;
  check_answer(): AnswerResult;
}

export class PracticeMath implements IProblem {
  title = "";
  first = 0;
  second = 0;
  focus_number = 0;
  first_must_be_greater = false;
  correct = false;
  answer = "";
  operation_symbol = "";
  message = "Welcome, time for some math practice!";
  start_time = performance.now();
  end_time = performance.now();
  problems: number[][] = [];

  load_problem_array(): void {
    console.log("load problem array");
    console.log(`focus number is ${ this.focus_number }`)
    console.log (`Problem length = ${ this.problems.length }`);
    const not_filtered = this.check_problems_not_filtered_by_focus_number();
    console.log(`Are problems not filtered? ${ not_filtered}`)

    if (this.problems.length == 0 || not_filtered ) {
      this.problems = [];
      const base_numbers: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      for (const f in base_numbers) {
        if (this.focus_number != 0) {
          console.log(`Loading problems with a focus number of ${ this.focus_number }`);
          this.problems.push([parseInt(f), parseInt(this.focus_number.toString())]);
        }
        else {
          for (const s in base_numbers) {
            console.log(`Loading problems without a focus number of ${ this.focus_number }`);
            this.problems.push([parseInt(f), parseInt(s)]);
          }
        }
      }
    }
  }

  check_problems_not_filtered_by_focus_number() : boolean {
    let filtered = true;
    let problem: number[];

    console.log(`Check current problems against focus number ${ this.focus_number } if not 0`);
    if (this.focus_number != 0){
      console.log(`Checking current problems against focus number ${ this.focus_number }`);
      for (problem of this.problems) {
        console.log(`  Right operand ${ problem[0] }`);
        console.log(`  Left operand ${ problem[1] }`);
        if ((problem[0] != this.focus_number) && (problem[1] != this.focus_number)) {
          console.log(`Neither operand is the focus number`);
          filtered = false
          break;
        }
      }
    }

    return !filtered
  }

  get_problem_index(): number {
    return parseInt((Math.random() * this.problems.length).toString());
  }

  get_current_problem(): number[] {
    console.log("get current_problem")
    this.load_problem_array();
    const index = this.get_problem_index();
    const current_problem = this.problems.splice(index, 1);
    return current_problem[0];
  }

  set_focus_number(focus_number: number): void {
    console.log("Set focus number");
    this.focus_number = focus_number;
  }

  update_numbers(): void {
    console.log("Update numbers");
    const current_problem = this.get_current_problem();
    const firstNumber = current_problem[0];
    const secondNumber = current_problem[1];

    if (this.first_must_be_greater) {
      this.first = firstNumber > secondNumber ? firstNumber : secondNumber;
      this.second = firstNumber < secondNumber ? firstNumber : secondNumber;
    } else {
      this.first = firstNumber;
      this.second = secondNumber;
    }
  }

  compute_answer(): number {
    throw "not yet implemented";
  }

  check_answer(): AnswerResult {
    this.end_time = performance.now();
    const computed_answer = this.compute_answer();

    console.log(`Answered with operands ${ this.first }, ${ this.second }.`);
    console.log(`  with answer computed ${ computed_answer } and ${ this.answer }`);

    this.correct = computed_answer == parseInt(this.answer);
    const result = new AnswerResult(this);
    if (this.correct) {
      console.log(`  Correct`);
      this.message = "Great Job!";
      this.update_numbers();
      this.answer = "";
    } else {
      console.log(`  Wrong`);
      this.answer = "";
      this.message = "Try Again - You can do it!";
    }
    this.start_time = performance.now();
    return result;
  }
}

export class AdditionProblem extends PracticeMath {
  title = "Addition";
  operation_symbol = "+";
  message = "Welcome, time for some addition practice!";

  compute_answer(): number {
    return (this.first +  this.second);
  }
}

export class SubtractionProblem extends PracticeMath {
  title = "Subtraction";
  first_must_be_greater = true;

  operation_symbol = "-";
  message = "Welcome, time for some subtraction practice!";

  compute_answer(): number {
    return this.first - this.second;
  }
}

export class MultiplicationProblem extends PracticeMath {
  title = "Multiplication";
  operation_symbol = "x";
  message = "Welcome, time for some multiplication practice!";

  compute_answer(): number {
    return this.first * this.second;
  }
}

export class DivisionProblem extends PracticeMath {
  title = "Division";
  operation_symbol = "/";
  message = "Welcome, time for some division practice!";

  compute_answer(): number {
    return this.first / this.second;
  }

  update_numbers(): void {
    const current_problem = this.get_current_problem();
    const firstNumber = current_problem[0];
    const secondNumber = current_problem[1];

    this.first = firstNumber * secondNumber;
    this.second = secondNumber;
    if (this.second == 0) {
      this.update_numbers();
    }
  }
}

export class AnswerResult {
  user_name = "";
  first_number: number;
  second_number: number;
  operation: string;
  answer: number;
  correct: boolean;
  time_ms: number;
  focus_number: number;

  constructor(problem: IProblem) {
    this.first_number = problem.first;
    this.second_number = problem.second;
    this.answer = parseInt(problem.answer);
    this.operation = problem.operation_symbol;
    this.correct = problem.correct;
    this.time_ms = problem.end_time - problem.start_time;
    this.focus_number = problem.focus_number;
  }
}
